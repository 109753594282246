import React from "react"
import Layout from "../components/layout"
import Header from "../components/addOns/header"
import Content from "../components/addOns/contentCards"
import SEO from "../components/addOns/seo"

const AddOns = () => {
  return (
    <Layout>
      <SEO />
      <Header />
      <Content />
    </Layout>
  )
}

export default AddOns
