import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocationsContext } from "../../contexts/locationsContext"
import { Select } from "antd"
import Card from "./card"
import "./contentCards.sass"

const { Option } = Select

const Selector = ({ handleFilter }) => {
  const {
    allSanityCategories: { edges: categories },
  } = useStaticQuery(graphql`
    query catergoriesQuery {
      allSanityCategories {
        edges {
          node {
            categoryName
            categorySlug
          }
        }
      }
    }
  `)

  return (
    <Select
      defaultValue="all"
      dropdownStyle={{ fontFamily: "Red Hat Display" }}
      onChange={e => handleFilter(e)}
    >
      <Option style={{ fontFamily: "Red Hat Display" }} value="all">
        Show All
      </Option>
      {categories.map(node => (
        <Option style={{ fontFamily: "Red Hat Display" }} value={node.node.categorySlug}>
          {node.node.categoryName}
        </Option>
      ))}
    </Select>
  )
}

export default Selector
