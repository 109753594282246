import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocationsContext } from "../../contexts/locationsContext"
import { Select } from "antd"
import Card from "./card"
import Selector from "./addOnsCategorySelector"
import "./contentCards.sass"

const { Option } = Select

const Content = () => {
  const {
    sanityAddonsPage: { addons },
  } = useStaticQuery(graphql`
    query AddonsContentCardsQuery {
      sanityAddonsPage {
        addons {
          _key
          title
          subTitle
          price
          image {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          colorlist
          loactions {
            locations {
              countryCode
            }
          }
          addOnCategories {
            elderLaw
            generalBusiness
            salesMarketing
          }
          addOnCategoriesSelector {
            categoryName
            categorySlug
          }
        }
      }
    }
  `)

  const [data, setData] = useState(null)
  const { currentLocation } = useContext(LocationsContext)
  const [integrations, setIntegrations] = useState(addons)

  useEffect(() => {
    setData(addons)
  }, [])

  const handleFilter = value => {
    if (value === "all") {
      setData(integrations)
      return
    }

    const filteredData = integrations.filter(addon =>
      addon.addOnCategoriesSelector
        .map(category => category.categorySlug)
        .includes(value)
    )

    setData(filteredData)
  }

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(addons)
      setData(addons)
    } else {
      const filteredData = addons.filter(
        integrations =>
          integrations.loactions && // prevent error, if it's null, return false
          integrations.loactions.locations
            .map(location => location.countryCode) // reconstruct country code as array of string
            .includes(currentLocation.countryCode) // chech if the country code same as current country code, then return true
      )
      setIntegrations(filteredData)
      setData(filteredData)
    }
  }, [currentLocation])

  if (!data) return <div></div>

  return (
    <div id="add_on_content">
      <div className="container">
        <div className="filter-bar">
          <Selector handleFilter={handleFilter} />
        </div>
        <div className="cards">
          {data.length > 0 ? (
            data.map(card => <Card data={card} key={card._key} />)
          ) : (
            <p>Sorry there are no addons available in your region </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Content
