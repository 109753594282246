import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HeroHeader from "../heroHeader"
import "./header.sass"

const Header = () => {
  const {
    sanityAddonsPage: { heroHeader },
  } = useStaticQuery(graphql`
    query AddonsPageHeaderQuery {
      sanityAddonsPage {
        heroHeader {
          waveColor
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          mainContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          subContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `)

  return (
    <div id="add_ons_header">
      <HeroHeader data={heroHeader} />
    </div>
  )
}

export default Header
