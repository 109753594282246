import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "./card.sass"

const Cards = ({ data }) => {
  return (
    <div className="add-ons-card">
      {data.image && (
        <div className="image-wrapper">
          <GatsbyImage
            image={data.image.asset.gatsbyImageData}
            alt={data.image.asset.originalFilename}
          />
        </div>
      )}

      <div className="content">
        <h4>{data.title}</h4>
        {data.price && <span className="price">Price: {data.price}</span>}
        <p>{data.subTitle}</p>
      </div>

      <Link
        to={`/add-ons/${data.title
          .toLowerCase()
          .replace(/\s+/g, "-")
          .slice(0, 200)}/`}
      >
        Learn More
      </Link>
      <span
        className="bottom-line-color"
        style={{ backgroundColor: data.colorlist }}
      />
    </div>
  )
}

export default Cards
